.main {
  width: 95%;
  max-width: 1070px;
  margin: auto;
}

.contactus-banner {
  position: relative;
  height: 330px;
}

.contactus-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  margin: auto;
  color: #fff;
  z-index: 4;
}

.banner-text h1 {
  font-weight: 500;
}

.banner-text p {
  font-size: 14px;
  font-weight: 500;
}

.contact-section {
  padding-block: 40px;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.contact-info {
  line-height: 1.6;
}

.contact-info h2 {
  font-weight: 500;
}

.contact-info p {
  font-size: 13px;
}

.office-info {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  gap: 20px;
  line-height: 1.6;
}

.office-info-p1,
.office-info-p2 {
  margin-block: 1rem;
}

.info {
  display: flex;
  gap: 8px;
  margin: 1rem 0;
}

.info .icon-wrapper {
  font-size: 14px;
  color: #C03221;
}

.info p,
.info a {
  color: black;
  font-size: 13px;
}

.info a {
  text-decoration: none;
  transition: 0.15s;
}

.info a:hover {
  color: #C03221;
}

.query-form-container {
  margin-top: 20px;
}

.inputs-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.input-element {
  padding: 8px 17px;
  outline: none;
  border: 1px solid rgb(214, 214, 214);
  background: rgb(247, 247, 247);
  transition: all 0.15s;
}

.input-element:focus {
  border-color: #C03221;
}

.textarea-container {
  margin-block: 16px;
}

.textarea-element {
  width: 100%;
}

.submit-btn {
  border: 1px solid #C03221;
  color: #C03221;
  transition: all 0.3s;
  background-color: transparent;
  padding: 8px 16px 14px 16px;
}

.submit-btn:hover {
  background-color: #C03221;
  color: #fff;
}

.submit-btn span {
  font-weight: 200;
}

.our-stores-section {
  padding-block: 40px;
}

.store {
  margin-block: 2rem;
}

.stores-title {
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 500;
}

.store-info {
  font-size: 13px;
  line-height: 1.6;
  text-align: center;
  color: gray;
}

.store img {
  max-width: 277px;
  margin: 0 auto 8px auto;
}

.store a {
  color: gray;
}

.storename {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #222;
}

.phone-link {
  transition: color 0.3s;
}

.phone-link:hover {
  color: #C03221;
}

.store-hours {
  margin-block: 18px;
}

.store-hours .title {
  margin-bottom: 8px;
  font-weight: 500;
  color: #222;
}

.viewmap-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding-block: 4px;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
}

.viewmap-link:hover {
  color: #C03221;
  border-bottom-color: #C03221;
}

/* 
=====
Media queries
=============
*/

@media (min-width: 768px) {
  .stores-container {
    display: flex;
    justify-content: space-around;
  }

  .store {
    margin-block: 0;
  }

  .store img {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .contact-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .stores-container {
    justify-content: space-between;
  }

  .store {
    display: flex;
    gap: 16px;
  }

  .store img {
    max-width: 240px;
  }

  .store-info {
    text-align: left;
  }
}
