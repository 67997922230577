.collageContainer {
  padding: 0 20rem;
  margin-top: 2rem !important;
  height: 70vh;
  margin-bottom: 2rem;
}

.container {
  width: 100%;
  padding: 0 5rem;
  margin-top: 2rem !important;
}

@media only screen and (max-width: 1680px) {
  .collageContainer,
  .container {
    padding: 0 15rem;
  }
}

@media only screen and (max-width: 1490px) {
  .collageContainer,
  .container {
    padding: 0 10rem;
  }
}

@media only screen and (max-width: 1324px) {
  .collageContainer,
  .container {
    padding: 0 5rem;
  }
}

@media only screen and (max-width: 990px) {
  .collageContainer,
  .container {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .collageContainer {
    height: fit-content;
  }
}

.collageCard {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.collageCard img {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform: scale(1.2);
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease;
}

.collageCard:hover img {
  transform: scale(1);
}

.collageCard::after {
  content: "";
  inset: 0;
  background: #00000080;
  position: absolute;
}

.collageCardcontent {
  position: absolute;
  bottom: 46px;
  left: 38px;
  z-index: 5;
}

.smallcardcontent {
  position: absolute;
  bottom: 46px;
  left: 38px;
  z-index: 5;
}

.smallcardcontent h6 {
  font-size: 1.5rem;
  line-height: 1.2em;
  color: white;
}

.smallcardcontent a {
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}

.collageCardcontent a {
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}

.collageCardcontent h6 {
  font-size: 2rem;
  line-height: 1.2em;
  color: white;
}

.h-50 {
  height: 50%;
}

@media only screen and (max-width: 600px) {
  .h-50 {
    height: auto;
  }

  .collageCard {
    min-height: 20rem !important;
  }
}

.ms-1 {
  margin-left: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

/* featured products card styling */

.slider {
  width: 100% !important;
  overflow-x: hidden;
  padding: 1rem 3rem;
}

/* slick carousel prev and next buttons */
.slick-prev {
  left: 1% !important;
  z-index: 1 !important;
  /* background-color: black !important; */
  color: black !important;
}
.slick-next {
  right: 1% !important;
  z-index: 1 !important;
  color: black !important;
  /* background-color: black !important; */
}

.featuredcard {
  width: 16rem !important;
  height: 386px;
}

.product-categories {
  display: flex;
}

.product-categories p {
  color: #cccccc;
  font-weight: 300;
  font-size: 0.8rem;
}

.product-price {
  display: flex;
}

.cardmedia {
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.cardmedia:hover .addtowishlistbtn {
  right: 35px;
  visibility: visible;
}

.cardmedia:hover .cardmediafooter {
  visibility: visible;
  opacity: 1;
}

.cardmediafooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 3rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease;
}

/* Rating Styles */
.ratingdiv {
  display: flex;
  align-items: center;
}
/* login page */
.login {
  background-image: url(/src/assets/images/loginBg.jpg);
  height: 92vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginBox {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.loginBox h3 {
  font-weight: 400;
  font-size: 2rem;
}

.loginBox label {
  color: #666;
  font-weight: 300;
  font-size: 1rem;
}

.link {
  margin-top: 1rem;
  font-size: 14px;
}

/* login page end */
/* brands carousel */
.brandscarousel {
  width: 100%;
  padding: 2rem 10rem;
  background-color: #ebedeb;
}

@media only screen and (max-width: 1064px) {
  .brandscarousel {
    padding: 2rem 5rem;
  }
}

@media only screen and (max-width: 706px) {
  .brandscarousel {
    padding: 2rem 2rem;
  }
}

.brandImage {
  width: 13rem;
  height: 8rem;
}

/* featured carousel */
.feauredsection {
  padding: 0 20rem;
}

@media only screen and (max-width: 1575px) {
  .feauredsection {
    padding: 0 15rem;
  }
}

@media only screen and (max-width: 1425px) {
  .feauredsection {
    padding: 0 10rem;
  }
}

@media only screen and (max-width: 1261px) {
  .feauredsection {
    padding: 0 5rem;
  }
}

@media only screen and (max-width: 1097px) {
  .feauredsection {
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 1097px) {
  .feauredsection {
    padding: 0 10rem;
  }
}

@media only screen and (max-width: 993px) {
  .feauredsection {
    padding: 0 5rem;
  }
}

@media only screen and (max-width: 841px) {
  .feauredsection {
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .feauredsection {
    padding: 0 8rem;
  }
}

@media only screen and (max-width: 665px) {
  .feauredsection {
    padding: 0 5rem;
  }
}

@media only screen and (max-width: 567px) {
  .feauredsection {
    padding: 0 1rem;
  }
}

/* footer styles */
/* footer {
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 1.4rem;
  color: #777;
  background-color: #f5f6f9;
} */

/* about us media query */

@media (min-width: 768px) {
  .vision-and-mission-section {
    display: flex;
    gap: 2rem;
  }

  .whoWeAre-section .middle-component,
  .brands-section .middle-component {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .brands-container {
    margin-top: 0;
  }

  .members-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

.member-image-container {
  position: relative;
}

.member {
  max-width: 376px;
  margin: auto;
}

.brands-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.brand-image {
  display: flex;
  align-items: center;
}

.brand-image img {
  width: auto;
  max-width: 100%;
  margin: auto;
}

.team-section {
  padding-block: 50px;
}
