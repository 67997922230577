/* featured card styles */
.featuredProductname {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.25;
    letter-spacing: -0.01em;
    min-height: 72px;
}

.new-price,
.old-price {
    font-weight: 400;
    letter-spacing: -0.01em;
}

.new-price {
    color: #ef837b;
    margin-right: 0.8rem;
}

.old-price {
    color: #ccc;
}

.reviewno {
    color: #ccc;
    letter-spacing: -0.01em;
    margin-left: 0.8rem;
}

.subheading{
    font-size: 1.5rem;
    text-align: center;
}

.subdescription{
    font-size: 0.8rem;
    text-align: center;
    color: #888484;
}

/* about us page */
.member-name h4 {
    font-weight: 500;
    font-size: 14px;
}

.member-name p {
    font-size: 13px;
}

.member:hover .member-name {
    opacity: 0;
    transform: translateY(-12px);
}


.member-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(128, 128, 128, 0.75);
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    overflow: hidden;
}

.member-title {
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    transform: translateY(20px);
    transition: transform 0.5s ease;
}

.member-title span {
    font-weight: normal;
}

.about-member {
    text-align: center;
    color: white;
    font-size: 14px;
    margin-block: 2rem;
    padding: 0 20px;
    line-height: 1.6;
    transform: translateY(20px);
    transition: transform 0.5s ease;
}

.social-icons {
    display: flex;
    gap: 50px;
    transform: translateY(20px);
    transition: transform 0.5s ease;
}

.social-link {
    color: white;
    transition: color 0.5s;
}

.social-link:hover {
    color: #3b82f6;
}

.member:hover .member-overlay {
    opacity: 1;
}

.member:hover .member-title,
.member:hover .about-member,
.member:hover .social-icons {
    opacity: 1;
    transform: translateY(0);
}

.member-name {
    text-align: center;
    padding: 32px;
    transition: all 0.35s;
}

.team-section-heading {
    text-align: center;
    margin-bottom: 40px;
}


.brands-section-about h3 {
    font-weight: 500;
    margin-bottom: 8px;
}

.brands-section-about p {
    font-size: 14px;
    line-height: 1.6;
}

a {
    text-decoration: none;
}

.middle-component {
    width: 95%;
    max-width: 1070px;
    margin: auto;
}

.aboutus-banner {
    position: relative;
}

.banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    margin: auto;
    color: #fff;
    z-index: 4;
}

.banner-text h1 {
    font-weight: 500;
}

.banner-text p {
    font-size: 14px;
    font-weight: 500;
}

.vision-and-mission-section {
    padding-bottom: 50px;
}

.about-child {
    margin: 2rem auto 1rem auto;
}

.about-child h3 {
    margin-bottom: 8px;
    font-weight: 500;
}

.about-child p {
    line-height: 1.6;
    font-size: 14px;
}

.whoWeAre-section {
    background-color: rgb(247, 247, 247);
    padding-block: 50px;
}

.whoWeAre-section h3 {
    font-weight: 500;
}

.whoWeAre-section h4 {
    color: #3b82f6;
    font-weight: 500;
    font-size: 14px;
    margin: 16px 0;
}

.whoWeAre-section p {
    line-height: 1.6;
    font-size: 14px;
}

.news-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #3b82f6;
    transition: all 0.3s;
    padding: 8px 16px;
    border: 1px solid #3b82f6;
    margin-block: 16px;
}

.news-link span {
    font-size: 12px;
}

.news-link:hover {
    color: #fff;
    background-color: #3b82f6;
}

.brands-section .middle-component {
    padding-block: 80px;
    border-bottom: 1px solid rgb(204, 204, 204);
}
