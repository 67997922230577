.searchInput {
    border-radius: 20px !important;
    height: 2.5rem !important;
    width: 0;
    visibility: hidden;
    color: black;
    background-color: white;
    border: none !important;
    outline: none !important;
    padding-left: 10px !important;
    transition-property: width, visibilty;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    position: relative;
}

.makeinputvisible {
    width: 100%;
    visibility: visible;
}

.loginInput {
    width: 100%;
    height: 3rem;
    padding-left: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
