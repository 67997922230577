.noborderbackgroundbutton {
    background: none !important;
    color: white !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.noborderbackgroundbutton::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px; /* Adjust the border thickness as needed */
    background-color: white; /* Adjust the border color as needed */
    transition: width 0.5s ease;
}

.noborderbackgroundbutton:hover::after {
    width: 100%; /* Set the border width to 100% on hover */
}

.noborderbackgroundbutton .MuiSvgIcon-root {
    transition-property: transform;
    transition-duration: 500ms;
    transition-timing-function: ease-in;
    transform: rotate(0deg);
}

.noborderbackgroundbutton:hover .MuiSvgIcon-root {
    transform: rotate(180deg);
}

.nostylebtn{
    border: none;
    outline: none;
    background: none;
    color: white;
    cursor: pointer;
}

.searchIconbtn{
    transform: translateY(0) !important;
    visibility: visible;
}

.closesearchbtn{
    position: absolute;
    left: 85%;
    top: 7px;
    visibility: hidden;
    transform: rotate(0);
}

.searchIconbtn,.closesearchbtn{
    transition-property: transform,visibility;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
}

.closesearchbtnactive{
    visibility: visible;
    transform: rotate(360deg);
}

.searchbtninactive{
    transform: translateY(-20px) !important;
    visibility: hidden;
}

/* used as the login button also */
.shopNowbtn{
    border: 2px solid #3e2ee8 !important;
    color: #3e2ee8 !important;
    transition-property: color,background-color;
    transition-duration: 300ms;
    transition-timing-function: ease;
    font-weight: 600 !important;
}

.shopNowbtn:hover{
    color: white !important;
    background-color: #3e2ee8 !important;
}

/* addtowish list button */
.addtowishlistbtn{
    width: 2rem;
    height: 2rem;
    outline: none;
    border-radius: 50%;
    background-color: white;
    color: aqua;
    border: 1px solid aqua;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* right: 35px; */
    top: 13px;
    right: 0;
    cursor: pointer;
    visibility: hidden;
    transition-property: right,visibility;
    transition-duration: 300ms;
    transition-timing-function: ease;
}

.addtowishlist {
    margin-left: 2rem;
    color: #008cba;
    border: none;
    background-color: #ffff;
    font-size: 1rem;
  }
  
  .button3:hover {
    text-decoration: underline;
  }