@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
}

.simplebar-placeholder {
  display: none;
}

body {
  @apply font-sans;
}

.inputC {
  @apply block w-full rounded-lg border border-gray p-2.5 text-sm text-gray  focus-visible:outline-none focus-visible:border-sec;
}

label {
  color: #353352;
}

.btn {
  @apply flex w-full items-center justify-center rounded-lg bg-primary px-5 py-2.5 text-sm font-medium text-white;
}

@media print {
  .printC {
    display: block;
  }
}